import { itemPages } from "../../../config/constantLists";
const DashboardLayout = () => import("../../../layouts/DashboardLayout.vue");
const Owners = () => import("./views/Owners");
const OwnerView = () => import("./views/OwnerView");

export default [
  {
    path: "/owners",
    component: DashboardLayout,
    meta: { auth: true },
    name: "owner",
    permission: ["view_any"],
    children: [
      {
        path: "",
        name: "contributor.owners",
        meta: { auth: true, permission: ["view_any"] },
        component: Owners,
      },
      {
        path: "view/:id?",
        name: "contributor.ownerView",
        meta: { auth: true, currentPage: itemPages.view, permission: ["view"] },
        component: OwnerView,
        props: true,
      },
      {
        path: "create",
        name: "contributor.ownerCreate",
        meta: {
          auth: true,
          currentPage: itemPages.create,
          permission: ["create"],
        },
        component: OwnerView,
      },
      {
        path: "update/:id?",
        name: "contributor.ownerUpdate",
        meta: {
          auth: true,
          currentPage: itemPages.update,
          permission: ["edit"],
        },
        component: OwnerView,
        props: true,
      },
    ],
  },
];
