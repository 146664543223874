import { itemPages } from "../../../config/constantLists";
const DashboardLayout = () => import("../../../layouts/DashboardLayout.vue");
const Trainers = () => import("./views/Trainers");
const TrainerView = () => import("./views/TrainerView");

export default [
  {
    path: "/trainers",
    component: DashboardLayout,
    meta: { auth: true },
    name: "trainer",
    children: [
      {
        path: "",
        name: "contributor.trainers",
        meta: { auth: true },
        component: Trainers,
      },
      {
        path: "view/:id?",
        name: "contributor.trainerView",
        meta: { auth: true, currentPage: itemPages.view },
        component: TrainerView,
        props: true,
      },
      {
        path: "create",
        name: "contributor.trainerCreate",
        meta: { auth: true, currentPage: itemPages.create },
        component: TrainerView,
      },
      {
        path: "update/:id?",
        name: "contributor.trainerUpdate",
        meta: { auth: true, currentPage: itemPages.update },
        component: TrainerView,
        props: true,
      },
    ],
  },
];
