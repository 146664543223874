// Layout
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Dashboard = () => import('./Dashboard')

// start Routes
export default [
  {
    path: '/',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        meta: { auth: true, name: 'Dashboard' },
        component: Dashboard
      }
    ]
  }
]
