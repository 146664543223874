import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Roles = () => import('./views/Roles')
const RoleView = () => import('./views/RoleView')
const Permissions = () => import('./views/Permissions')

export default [
  {
    path: '/roles',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'administration.roles',
        meta: { auth: true },
        component: Roles
      },
      {
        path: 'view/:id?',
        name: 'administration.roleView',
        meta: { auth: true, currentPage: itemPages.view },
        component: RoleView,
        props: true
      },
      {
        path: 'create',
        name: 'administration.roleCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: RoleView
      },
      {
        path: 'update/:id?',
        name: 'administration.roleUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: RoleView,
        props: true
      },
      {
        path: 'permissions/:id?',
        name: 'administration.permissions',
        meta: { auth: true },
        component: Permissions,
        props: true
      }
    ]
  }
]
