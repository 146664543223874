import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const RaceRecordings = () => import('./views/RaceRecordings')
const RaceRecordingView = () => import('./views/RaceRecordingView')

export default [
  {
    path: '/race-recordings',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.raceRecordings',
        meta: { auth: true },
        component: RaceRecordings
      },
      {
        path: 'view/:id?',
        name: 'events.raceRecordingView',
        meta: { auth: true, currentPage: itemPages.view },
        component: RaceRecordingView,
        props: true
      },
      {
        path: 'create/:raceId?',
        name: 'events.raceRecordingCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: RaceRecordingView,
        props: true
      },
      {
        path: 'update/:id?',
        name: 'events.raceRecordingUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: RaceRecordingView,
        props: true
      }
    ]
  }
]
