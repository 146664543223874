<template>
  <div class="module-actions">
    <input-form
      v-if="searchStatus"
      v-model="textSearch"
      :placeholder="$t('global.search')"
    />

    <div class="btns">
      <!-- <b-button variant="primary" v-b-modal.modal-1>Launch demo modal</b-button> -->
      <b-button
        v-if="uploadFileStatus || downloadLoading"
        variant="primary"
        @click="openModal"
        >{{ $t("global.importExcel") }}</b-button
      >
      <b-button
        v-if="createStatus"
        variant="primary"
        class="rounded"
        :to="{ name: createRouteName, params: createRouteParams }"
        >{{ createBtnLabel }}</b-button
      >
    </div>

    <b-modal id="modal-1" :title="$t('global.importExcel')" :hide-footer="true">
      <b-form class="form-top p-4" @submit.prevent="uploadFile">
        <b-button
          class="btn-download"
          variant="primary"
          @click="downloadSample"
          :disabled="downloadLoading"
        >
          <b-spinner v-if="downloadLoading" small></b-spinner>
          <span class="mx-2">{{ $t("global.downloadSample") }}</span>
        </b-button>

        <input-file
          v-if="uploadFileStatus"
          v-model="file"
          :name="$t('global.file')"
          :label="$t('global.file')"
          :previewStatus="false"
          accept=".xls,.xlsx"
        />

        <form-btns
          v-if="uploadFileStatus"
          :label="$t('global.upload')"
          :loading="loading"
        />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { core } from "../../config/pluginInit";
import mainService from "../../services/main";

export default {
  data() {
    return {
      loading: false,
      downloadLoading: false,
      textSearch: "",
      awaitingSearch: false,
      file: null,
    };
  },
  props: {
    searchStatus: { default: true, type: Boolean },
    createStatus: { default: true, type: Boolean },
    createBtnLabel: { default: "", type: String },
    createRouteName: { default: "", type: String },
    createRouteParams: { default: () => {}, type: Object },
    downloadSampleStatus: { default: false, type: Boolean },
    downloadSampleUrl: { default: "", type: String },
    uploadFileStatus: { default: false, type: Boolean },
    uploadFileUrl: { default: "", type: String },
  },
  methods: {
    openModal() {
      this.$bvModal.show("modal-1");
    },
    closeModal() {
      this.$bvModal.hide("modal-1");
    },
    downloadSample() {
      this.downloadLoading = true;
      mainService
        .downloadFile(this.downloadSampleUrl)
        .then((response) => {
          core.showSnackbar("success", this.$t("global.success"));
          window.open(`${response.data.fileUrl}`, "_blank");
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    uploadFile() {
      if (!this.file) {
        core.showSnackbar("error", this.$t("global.pleaseSelectFile"));
        return true;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("excel", this.file);

      mainService
        .uploadFile(formData, this.uploadFileUrl)
        .then((response) => {
          core.showSnackbar("success", response.data.message);
          this.$emit("search", this.textSearch);
          this.closeModal();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    textSearch: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.$emit("search", this.textSearch);
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
};
</script>

<style lang="scss">
.module-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .btns {
    display: flex;
    column-gap: 0.5rem;
    justify-content: flex-end;
    flex: 1;
  }
}
.modal-body {
  .btn-download {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
</style>
