import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import i18n from '../i18n'

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    // const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    // const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
    const regex = new RegExp('^[+-]?\\d+(\\.\\d+)?$')

    return {
      valid: regex.test(value)
    }
  },
  // message: 'The {_field_} field must contain only decimal values'
  message: i18n.t('validation.decimal')
})

Object.keys(rules).forEach(rule => {
  if (rule === 'required') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.required') })
  } else if (rule === 'email') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.email') })
  } else if (rule === 'numeric') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.numeric') })
  } else {
    extend(rule, { ...rules[rule], message: i18n.t('validation.notValid') })
  }
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
