import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Tracks = () => import('./views/Tracks')
const TrackView = () => import('./views/TrackView')

export default [
  {
    path: '/tracks',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.tracks',
        meta: { auth: true },
        component: Tracks
      },
      {
        path: 'view/:id?',
        name: 'events.trackView',
        meta: { auth: true, currentPage: itemPages.view },
        component: TrackView,
        props: true
      },
      {
        path: 'create',
        name: 'events.trackCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: TrackView
      },
      {
        path: 'update/:id?',
        name: 'events.trackUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: TrackView,
        props: true
      }
    ]
  }
]
