import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const HorseGenders = () => import('./views/HorseGenders')
const HorseGenderView = () => import('./views/HorseGenderView')

export default [
  {
    path: '/horse-genders',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'contributor.horseGenders',
        meta: { auth: true },
        component: HorseGenders
      },
      {
        path: 'view/:id?',
        name: 'contributor.horseGenderView',
        meta: { auth: true, currentPage: itemPages.view },
        component: HorseGenderView,
        props: true
      },
      {
        path: 'create',
        name: 'contributor.horseGenderCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: HorseGenderView
      },
      {
        path: 'update/:id?',
        name: 'contributor.horseGenderUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: HorseGenderView,
        props: true
      }
    ]
  }
]
