import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const ContactUs = () => import('./views/ContactUs')
const ContactUsView = () => import('./views/ContactUsView')

export default [
  {
    path: '/contact-us',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'clients.contactUs',
        meta: { auth: true },
        component: ContactUs
      },
      {
        path: 'view/:id?',
        name: 'clients.contactUsView',
        meta: { auth: true, currentPage: itemPages.view },
        component: ContactUsView,
        props: true
      }
    ]
  }
]
