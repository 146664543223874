import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Races = () => import('./views/Races')
const RaceView = () => import('./views/RaceView')
const RaceUpdateResult = () => import('./views/RaceUpdateResult')

export default [
  {
    path: '/races',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.races',
        meta: { auth: true },
        component: Races
      },
      {
        path: 'view/:id?',
        name: 'events.raceView',
        meta: { auth: true, currentPage: itemPages.view },
        component: RaceView,
        props: true
      },
      {
        path: 'create',
        name: 'events.raceCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: RaceView
      },
      {
        path: 'update/:id?',
        name: 'events.raceUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: RaceView,
        props: true
      },
      {
        path: 'update-race-result/:id?',
        name: 'events.updateRaceResult',
        meta: { auth: true },
        component: RaceUpdateResult,
        props: true
      }
    ]
  }
]
