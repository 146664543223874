import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Clients = () => import('./views/Clients')
const ClientView = () => import('./views/ClientView')

export default [
  {
    path: '/clients',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'clients.clients',
        meta: { auth: true },
        component: Clients
      },
      {
        path: 'view/:id?',
        name: 'clients.clientView',
        meta: { auth: true, currentPage: itemPages.view },
        component: ClientView,
        props: true
      }
    ]
  }
]
