// Layout
const AuthLayout = () => import('@/layouts/AuthLayouts/AuthLayout')
const SignIn1 = () => import('@/views/AuthPages/Default/SignIn1')
// const SignUp1 = () => import('@/views/AuthPages/Default/SignUp1')

// start Routes
export default [
  {
    path: '/auth',
    component: AuthLayout,
    meta: { auth: false },
    children: [
      {
        path: 'sign-in',
        name: 'auth.sign-in',
        meta: { auth: false },
        component: SignIn1
      }
      // {
      //   path: 'sign-up',
      //   name: 'auth.sign-up',
      //   meta: { auth: false },
      //   component: SignUp1
      // }
    ]
  }
]
