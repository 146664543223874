import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const TermsAndConditions = () => import('./views/TermsAndConditions')
const TermsAndConditionView = () => import('./views/TermsAndConditionView')

export default [
  {
    path: '/terms-and-conditions',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'generalSettings.termsAndConditions',
        meta: { auth: true },
        component: TermsAndConditions
      },
      {
        path: 'view/:id?',
        name: 'generalSettings.termsAndConditionView',
        meta: { auth: true, currentPage: itemPages.view },
        component: TermsAndConditionView,
        props: true
      },
      // {
      //   path: 'create',
      //   name: 'generalSettings.termsAndConditionCreate',
      //   meta: { auth: true, currentPage: itemPages.create },
      //   component: TermsAndConditionView
      // },
      {
        path: 'update/:id?',
        name: 'generalSettings.termsAndConditionUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: TermsAndConditionView,
        props: true
      }
    ]
  }
]
