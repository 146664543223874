import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Sponsors = () => import('./views/Sponsors')
const SponsorView = () => import('./views/SponsorView')

export default [
  {
    path: '/sponsors',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.sponsors',
        meta: { auth: true },
        component: Sponsors
      },
      {
        path: 'view/:id?',
        name: 'events.sponsorView',
        meta: { auth: true, currentPage: itemPages.view },
        component: SponsorView,
        props: true
      },
      {
        path: 'create',
        name: 'events.sponsorCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: SponsorView
      },
      {
        path: 'update/:id?',
        name: 'events.sponsorUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: SponsorView,
        props: true
      }
    ]
  }
]
