import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Horses = () => import('./views/Horses')
const HorseView = () => import('./views/HorseView')

export default [
  {
    path: '/horses',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'contributor.horses',
        meta: { auth: true },
        component: Horses
      },
      {
        path: 'view/:id?',
        name: 'contributor.horseView',
        meta: { auth: true, currentPage: itemPages.view },
        component: HorseView,
        props: true
      },
      {
        path: 'create',
        name: 'contributor.horseCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: HorseView
      },
      {
        path: 'update/:id?',
        name: 'contributor.horseUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: HorseView,
        props: true
      }
    ]
  }
]
