import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Participants = () => import('./views/Participants')
const ParticipantView = () => import('./views/ParticipantView')

export default [
  {
    path: '/participants',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.participants',
        meta: { auth: true },
        component: Participants
      },
      {
        path: 'view/:id?',
        name: 'events.participantView',
        meta: { auth: true, currentPage: itemPages.view },
        component: ParticipantView,
        props: true
      },
      {
        path: 'create',
        name: 'events.participantCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: ParticipantView
      },
      {
        path: 'update/:id?',
        name: 'events.participantUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: ParticipantView,
        props: true
      }
    ]
  }
]
