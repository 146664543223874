// Main Pages
import authRoutes from './mainPages/auth/auth.routes'
import dashboardRoutes from './mainPages/dashboard/dashboard.routes'

// Contributors
import ownersRoutes from './contributors/owners/owners.routes'
import trainersRoutes from './contributors/trainers/trainers.routes'
import horsesRoutes from './contributors/horses/horses.routes'
import horseGendersRoutes from './contributors/horseGenders/horseGenders.routes'
import jockeysRoutes from './contributors/jockeys/jockeys.routes'

// Events
import competitionsRoutes from './events/competitions/competitions.routes'
import sponsorsRoutes from './events/sponsors/sponsors.routes'
import racesRoutes from './events/races/races.routes'
import raceRecordingsRoutes from './events/raceRecordings/raceRecordings.routes'
import tracksRoutes from './events/tracks/tracks.routes'
import participantsRoutes from './events/participants/participants.routes'
import predictionsRoutes from './events/predictions/predictions.routes'

// GeneralSettings
import countriesRoutes from './generalSettings/countries/countries.routes'
import citiesRoutes from './generalSettings/cities/cities.routes'
import locationsRoutes from './generalSettings/locations/locations.routes'
import contactUsTopicsRoutes from './generalSettings/contactUsTopics/contactUsTopics.routes'
import termsAndConditionsRoutes from './generalSettings/termsAndConditions/termsAndConditions.routes'
import tutorialHelpsRoutes from './generalSettings/tutorialHelps/tutorialHelps.routes'
import faqsRoutes from './generalSettings/faqs/faqs.routes'
import aboutUsRoutes from './generalSettings/aboutUs/aboutUs.routes'

// Clients
import contactUsRoutes from './clients/contactUs/contactUs.routes'
import clientsRoutes from './clients/clients/clients.routes'

// Administration
import systemSettingsRoutes from './administration/systemSettings/systemSettings.routes'
import usersRoutes from './administration/users/users.routes'
import rolesRoutes from './administration/roles/roles.routes'

// Transactions
import systemTransactionsRoutes from './transactions/systemTransactions/systemTransactions.routes'
import clientTransactionsRoutes from './transactions/clientTransactions/clientTransactions.routes'
import systemWalletsRoutes from './transactions/systemWallets/systemWallets.routes'

export default [
  ...authRoutes,
  ...dashboardRoutes,
  ...ownersRoutes,
  ...trainersRoutes,
  ...horsesRoutes,
  ...horseGendersRoutes,
  ...jockeysRoutes,
  ...competitionsRoutes,
  ...sponsorsRoutes,
  ...racesRoutes,
  ...raceRecordingsRoutes,
  ...tracksRoutes,
  ...participantsRoutes,
  ...predictionsRoutes,
  ...countriesRoutes,
  ...citiesRoutes,
  ...locationsRoutes,
  ...contactUsTopicsRoutes,
  ...termsAndConditionsRoutes,
  ...tutorialHelpsRoutes,
  ...faqsRoutes,
  ...aboutUsRoutes,
  ...contactUsRoutes,
  ...clientsRoutes,
  ...systemSettingsRoutes,
  ...usersRoutes,
  ...rolesRoutes,
  ...systemTransactionsRoutes,
  ...clientTransactionsRoutes,
  ...systemWalletsRoutes
]
