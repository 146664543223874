import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const ContactUsTopics = () => import('./views/ContactUsTopics')
const ContactUsTopicView = () => import('./views/ContactUsTopicView')

export default [
  {
    path: '/contact-us-topics',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'generalSettings.contactUsTopics',
        meta: { auth: true },
        component: ContactUsTopics
      },
      {
        path: 'view/:id?',
        name: 'generalSettings.contactUsTopicView',
        meta: { auth: true, currentPage: itemPages.view },
        component: ContactUsTopicView,
        props: true
      },
      {
        path: 'create',
        name: 'generalSettings.contactUsTopicCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: ContactUsTopicView
      },
      {
        path: 'update/:id?',
        name: 'generalSettings.contactUsTopicUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: ContactUsTopicView,
        props: true
      }
    ]
  }
]
