<!-- Docs

Props:
1- Design Cell
  text => String
  icon => (class of line awesome)
  color => (text-${color}: for example 'primary')

2- showIf => condition must be a true to show cell

3- Routes
  ifNavigate => Boolean
  routerName => String
  routerPath => String
  routerParams => String

4- takeAction ($emit)
   takeActionParams => (get value of key in row)

5- change Status (with api)
-->
<template>
  <div>
    <div v-if="cellActions.actions.length"  class="d-flex justify-content-center w-full gap_1">
      <template v-for="(i, key) in cellActions.actions">
        <span
          v-if="i.showIf ? i.showIf(dataItem) : true"
          :key="key"
          @click="fireAction(i)"
        >
          <i v-b-tooltip.top="`${i.text}`" :class="[i.icon, `text-${i.color}`]" />
        </span>
      </template>
    </div>
  </div>
</template>
<script>
import EventBus from '@/eventBus'
export default {
  props: {
    cellActions: {
      type: Object
    },
    dataItem: {
      type: Object
    },
    baseURL: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      modalTitle: '',
      modalColor: 'danger'
    }
  },
  methods: {
    fireAction (action) {
      if (action.ifNavigate) {
        let params = {}
        let path = action.routePath || null

        const actionParams = action.routeParams || null
        if (action.getParams) {
          params = action.getParams(this.dataItem)
        } else if (action.routePath) {
          const pathParams = action.routePath.split('/').filter(e => e.includes(':')).map(e => e.replace(':', ''))
          pathParams.forEach(e => {
            params[e] = this.dataItem[e]
            path = path.replace(`:${e}`, this.dataItem[e])
          })
        } else if (Array.isArray(actionParams)) {
          actionParams.forEach(e => {
            params[e] = this.dataItem[e]
          })
        } else params[actionParams || 'id'] = this.dataItem[actionParams || 'id']
        this.$router.push(
          path || {
            name: action.routeName,
            params
          }
        )
      } else if (action.actionName) {
        console.log(action)
        let objData = {}
        if (action.actionParams === 'fullObj') {
          objData = this.dataItem
        } else if (action.actionParams) {
          action.actionParams.forEach(params => {
            if (this.dataItem[params]) {
              objData[params] = this.dataItem[params]
            }
          })
        }
        this.$root.$emit(action.actionName, objData)
      } else if (action.showAlert) {
        EventBus.$emit('openDeleteModal', {
          actionHeader: action.actionHeader ? action.actionHeader : this.$t('global.delete'),
          titleHeader: action.titleHeader ? action.titleHeader : '',
          textContent: action.textContent ? this.dataItem[action.textContent] : '',
          baseURL: this.baseURL,
          url: action.url ? action.url : '',
          rowId: this.dataItem.id,
          question: action.question ? action.question : this.$t('delete.question', { titleHeader: action.titleHeader }),
          textDeleteButton: action.textDeleteButton ? action.textDeleteButton : this.$t('delete.yes', { msg: action.actionHeader }),
          textCancelButton: action.textCancelButton ? action.textCancelButton : this.$t('delete.no'),
          icon: action.iconClass ? action.iconClass : 'las la-trash-alt',
          type: action.type ? action.type : 'delete',
          actionOnAlert: action.actionOnAlert ? action.actionOnAlert : ''
        })
      }
    }
  }
}
</script>
