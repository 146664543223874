export const itemPages = {
  view: 'view',
  create: 'create',
  update: 'update'
}

export const GENDERS = [
  { id: 'male', name: 'male' },
  { id: 'female', name: 'female' }
]

export const MARITAL_STATUS = [
  { id: 'single', name: 'single' },
  { id: 'married', name: 'married' },
  { id: 'widowed', name: 'widowed' },
  { id: 'divorced', name: 'divorced' },
  { id: 'seperated', name: 'seperated' }
]
