import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const SystemTransactions = () => import('./views/SystemTransactions')
const SystemTransactionView = () => import('./views/SystemTransactionView')

export default [
  {
    path: '/system-transactions',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'transactions.systemTransactions',
        meta: { auth: true },
        component: SystemTransactions
      },
      {
        path: 'view/:id?',
        name: 'transactions.systemTransactionView',
        meta: { auth: true, currentPage: itemPages.view },
        component: SystemTransactionView,
        props: true
      }
    ]
  }
]
