import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Locations = () => import('./views/Locations')
const LocationView = () => import('./views/LocationView')

export default [
  {
    path: '/locations',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'generalSettings.locations',
        meta: { auth: true },
        component: Locations
      },
      {
        path: 'view/:id?',
        name: 'generalSettings.locationView',
        meta: { auth: true, currentPage: itemPages.view },
        component: LocationView,
        props: true
      },
      {
        path: 'create',
        name: 'generalSettings.locationCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: LocationView
      },
      {
        path: 'update/:id?',
        name: 'generalSettings.locationUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: LocationView,
        props: true
      }
    ]
  }
]
