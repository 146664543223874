import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Users = () => import('./views/Users')
const UserView = () => import('./views/UserView')

export default [
  {
    path: '/users',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'administration.users',
        meta: { auth: true },
        component: Users
      },
      {
        path: 'view/:id?',
        name: 'administration.userView',
        meta: { auth: true, currentPage: itemPages.view },
        component: UserView,
        props: true
      },
      {
        path: 'create',
        name: 'administration.userCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: UserView
      },
      {
        path: 'update/:id?',
        name: 'administration.userUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: UserView,
        props: true
      }
    ]
  }
]
