import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const TutorialHelps = () => import('./views/TutorialHelps')
const TutorialHelpView = () => import('./views/TutorialHelpView')

export default [
  {
    path: '/tutorial-helps',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'generalSettings.tutorialHelps',
        meta: { auth: true },
        component: TutorialHelps
      },
      {
        path: 'view/:id?',
        name: 'generalSettings.tutorialHelpView',
        meta: { auth: true, currentPage: itemPages.view },
        component: TutorialHelpView,
        props: true
      },
      {
        path: 'create',
        name: 'generalSettings.tutorialHelpCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: TutorialHelpView
      },
      {
        path: 'update/:id?',
        name: 'generalSettings.tutorialHelpUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: TutorialHelpView,
        props: true
      }
    ]
  }
]
