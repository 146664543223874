import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const SystemSettings = () => import('./views/SystemSettings')
const SystemSettingView = () => import('./views/SystemSettingView')

export default [
  {
    path: '/system-settings',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'administration.systemSettings',
        meta: { auth: true },
        component: SystemSettings
      },
      {
        path: 'view/:id?',
        name: 'administration.systemSettingView',
        meta: { auth: true, currentPage: itemPages.view },
        component: SystemSettingView,
        props: true
      },
      {
        path: 'create',
        name: 'administration.systemSettingCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: SystemSettingView
      },
      {
        path: 'update/:id?',
        name: 'administration.systemSettingUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: SystemSettingView,
        props: true
      }
    ]
  }
]
