<template>
  <b-form-group>
    <template #label>
      <label :for="name">
        {{ label }}
        <span v-if="validate.includes('required')" class="required-status">*</span>
      </label>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="name"
      :rules="validate"
    >
      <!-- <b-form-file
        v-model="customFile"
        :id="id"
        :disabled="disabled"
        :class="['form-control bg-white' , { 'is-invalid': errors.length > 0 }]"
        :placeholder="placeholder"
        :drop-placeholder="placeholder"
        :browseText="$t('form.browse')"
      ></b-form-file> -->
      <!-- <label for="my-file">Select Image</label> -->
      <input
        v-if="!disabled"
        type="file"
        :accept="accept ? accept : previewStatus ? 'image/*' : 'all'"
        @change="previewImage"
        :id="id"
        :class="['form-control-file bg-white' , { 'is-invalid': errors.length > 0 }]"
      >

      <div v-if="previewStatus" class="preview-container border p-2 mt-3">
        <p>{{ $t('form.preview') }}:</p>
        <template v-if="preview">
          <img :src="preview" class="img-fluid" />
          <p class="mb-0">{{ $t('form.fileName') }}: {{ image.name }}</p>
          <p class="mb-0">{{ $t('form.size') }}: {{ image.size/1024 }}{{ $t('form.KB') }}</p>
        </template>
        <template v-else-if="defaultUrl">
          <img :src="defaultUrl" class="img-fluid" />
        </template>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
export default {
  props: {
    label: { default: '', type: String },
    name: { default: 'field', type: String },
    id: { default: '', type: String },
    validate: { default: '', required: false },
    value: { default: '', type: String },
    placeholder: { default: '', type: String },
    disabled: { default: false },
    defaultUrl: { default: null, type: String },
    previewStatus: { default: true, type: Boolean },
    accept: { default: '', type: String }
  },
  data: function () {
    return {
      // customFile: null,
      preview: null,
      image: null
    }
  },
  methods: {
    previewImage (event) {
      const input = event.target
      if (input.files && input.files.length > 0) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.preview = e.target.result
        }
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      } else {
        this.preview = null
        this.image = null
      }
    }
  },
  watch: {
    // customFile: function (val) {
    //   this.$emit('input', this.customFile)
    // }
    image: function (val) {
      this.$emit('input', this.image)
    },
    value (val) {
      if (val == null) {
        this.preview = null
        this.image = null
      }
    }
  }
}
</script>

<style lang="scss">
.preview-container img {
  width: 100%;
  max-width: 230px;
  height: 100%;
  max-height: 230px;
  object-fit: contain;
}
</style>
