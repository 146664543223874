import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const FAQs = () => import('./views/FAQs')
const FAQView = () => import('./views/FAQView')

export default [
  {
    path: '/faq',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'generalSettings.faqs',
        meta: { auth: true },
        component: FAQs
      },
      {
        path: 'view/:id?',
        name: 'generalSettings.faqView',
        meta: { auth: true, currentPage: itemPages.view },
        component: FAQView,
        props: true
      },
      {
        path: 'create',
        name: 'generalSettings.faqCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: FAQView
      },
      {
        path: 'update/:id?',
        name: 'generalSettings.faqUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: FAQView,
        props: true
      }
    ]
  }
]
