import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
// const DashboardLayout = () => import('../layouts/DashboardLayout.vue')
// const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')
// /* Dashboards View */
// const Dashboard = () => import('../views/Dashboards/Dashboard.vue')

// /* Authentic View */
// const SignIn1 = () => import('../views/AuthPages/Default/SignIn1')
// const SignUp1 = () => import('../views/AuthPages/Default/SignUp1')
// const RecoverPassword1 = () => import('../views/AuthPages/Default/RecoverPassword1')
// const LockScreen1 = () => import('../views/AuthPages/Default/LockScreen1')
// const ConfirmMail1 = () => import('../views/AuthPages/Default/ConfirmMail1')
// const Callback = () => import('../views/AuthPages/Default/Callback')
// /* Icon Views */
// const IconDripicons = () => import('../views/Icons/IconDripicons')
// const IconFontawesome5 = () => import('../views/Icons/IconFontawesome5')
// const IconLineAwesome = () => import('../views/Icons/IconLineAwesome')
// const IconRemixicon = () => import('../views/Icons/IconRemixicon')
// const IconUnicons = () => import('../views/Icons/IconUnicons')
// /* Charts View */
// const ApexCharts = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ApexChart" */ '../views/Charts/ApexCharts')
// const AmCharts = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "AmCharts" */'../views/Charts/AmCharts')
// const HighCharts = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "HighCharts" */'../views/Charts/HighCharts')
// /* User View */
// const Profile = () => import('../views/User/Profile')
// const ProfileEdit = () => import('../views/User/ProfileEdit')
// const AddUser = () => import('../views/User/AddUser')
// const UserList = () => import('../views/User/UserList')
// const AccountSetting = () => import('../views/User/AccountSetting')
// const PrivacySetting = () => import('../views/User/PrivacySetting')
// /* Plugins Views */
// const DatepickerDemo = () => import('../views/Plugins/DatepickerDemo')
// const SelectDemo = () => import('../views/Plugins/SelectDemo')
// const DragDropDemo = () => import('../views/Plugins/DragDropDemo')

// Import Business Landing
import endUserRoutes from '@/modules/endUser.routes'
// const BlankPage = () => import('../views/Pages/BlankPage')
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')

Vue.use(VueRouter)

// const iconChildRoute = (prop) => [
//   {
//     path: 'dripicons',
//     name: prop + '.dripicons',
//     meta: { auth: true, name: 'Dripicons' },
//     component: IconDripicons
//   },
//   {
//     path: 'fontawesome-5',
//     name: prop + '.fontawesome-5',
//     meta: { auth: true, name: 'Font Awsome' },
//     component: IconFontawesome5
//   },
//   {
//     path: 'lineawesome',
//     name: prop + '.lineawesome',
//     meta: { auth: true, name: 'Line Awsome' },
//     component: IconLineAwesome
//   },
//   {
//     path: 'remixicon',
//     name: prop + '.remixicon',
//     meta: { auth: true, name: 'Remixicon' },
//     component: IconRemixicon
//   },
//   {
//     path: 'unicons',
//     name: prop + '.unicons',
//     meta: { auth: true, name: 'Unicon' },
//     component: IconUnicons
//   }
// ]

// const chartChildRoutes = (prop) => [
//   {
//     path: 'apex-charts',
//     name: prop + '.apex',
//     meta: { auth: true, name: 'Apex Chart' },
//     component: ApexCharts
//   },
//   {
//     path: 'am-charts',
//     name: prop + '.am',
//     meta: { auth: true, name: 'Am Chart' },
//     component: AmCharts
//   },
//   {
//     path: 'high-charts',
//     name: prop + '.high',
//     meta: { auth: true, name: 'High Chart' },
//     component: HighCharts
//   }
// ]

// const authChildRoutes = (prop) => [
//   {
//     path: 'sign-in',
//     name: prop + '.sign-in',
//     meta: { auth: true },
//     component: SignIn1
//   },
//   {
//     path: 'sign-up',
//     name: prop + '.sign-up',
//     meta: { auth: true },
//     component: SignUp1
//   },
//   {
//     path: 'password-reset1',
//     name: prop + '.password-reset1',
//     meta: { auth: true },
//     component: RecoverPassword1
//   },
//   {
//     path: 'lock-screen1',
//     name: prop + '.lock-screen1',
//     meta: { auth: true },
//     component: LockScreen1
//   },
//   {
//     path: 'confirm-mail1',
//     name: prop + '.confirm-mail1',
//     meta: { auth: true },
//     component: ConfirmMail1
//   }
// ]

// const userChildRoute = (prop) => [
//   {
//     path: 'profile',
//     name: prop + '.profile',
//     meta: { auth: true, name: 'Profile' },
//     component: Profile
//   },
//   {
//     path: 'profile-edit/:id',
//     name: prop + '.edit',
//     meta: { auth: true, name: 'Edit Profile' },
//     component: ProfileEdit
//   },
//   {
//     path: 'add-user',
//     name: prop + '.add',
//     meta: { auth: true, name: 'Add Profile' },
//     component: AddUser
//   },
//   {
//     path: 'user-list',
//     name: prop + '.list',
//     meta: { auth: true, name: 'User List' },
//     component: UserList
//   },
//   {
//     path: 'account-setting',
//     name: prop + '.account-setting',
//     meta: { auth: true, name: 'Account Setting' },
//     component: AccountSetting
//   },
//   {
//     path: 'privacy-setting',
//     name: prop + '.privacy-setting',
//     meta: { auth: true, name: 'Privacy Setting' },
//     component: PrivacySetting
//   }
// ]

// const pluginsChildRoute = (prop) => [
//   {
//     path: 'datepicker',
//     name: prop + '.datepicker',
//     meta: { auth: true, name: 'Datepicker' },
//     component: DatepickerDemo
//   },
//   {
//     path: 'select',
//     name: prop + '.select',
//     meta: { auth: true, name: 'Select' },
//     component: SelectDemo
//   },
//   {
//     path: 'draggable',
//     name: prop + '.draggable',
//     meta: { auth: true, name: 'Draggable' },
//     component: DragDropDemo
//   }
// ]
// const routes = [
//   {
//     path: '/',
//     name: 'dashboard',
//     component: DashboardLayout,
//     meta: { auth: true },
//     children: [
//       {
//         path: '',
//         name: 'dashboard.home-2',
//         meta: { auth: true, name: 'Home 2' },
//         component: Dashboard
//       }
//     ]
//   },
//   {
//     path: '/chart',
//     name: 'chart',
//     component: DashboardLayout,
//     meta: { auth: true },
//     children: chartChildRoutes('chart')
//   },
//   {
//     path: '/icon',
//     name: 'icon',
//     component: DashboardLayout,
//     meta: { auth: true },
//     children: iconChildRoute('icon')
//   },
//   {
//     path: '/auth',
//     name: 'auth1',
//     component: AuthLayout,
//     meta: { auth: true },
//     children: authChildRoutes('auth1')
//   },
//   {
//     path: '/user',
//     name: 'user',
//     component: DashboardLayout,
//     meta: { auth: true },
//     children: userChildRoute('user')
//   },
//   {
//     path: '/plugins',
//     name: 'plugins',
//     component: DashboardLayout,
//     meta: { auth: true },
//     children: pluginsChildRoute('plugins')
//   },
//   {
//     path: '/callback',
//     name: 'callback',
//     meta: { auth: false },
//     component: Callback
//   }
// ]

const routes = [
  ...endUserRoutes,
  // {
  //   path: '/blank-page',
  //   name: 'BlankPage',
  //   component: BlankPage
  // },
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/sign-in', '/auth/sign-up']
  if (publicPages.includes(to.path)) {
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
  }
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next('/auth/sign-in')
    } else if (to.name === 'dashboard' || to.name === 'dashboard.home-1') {
      return next()
    }
  }
  next()
})

export default router
