import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const ClientTransactions = () => import('./views/ClientTransactions')
const ClientTransactionView = () => import('./views/ClientTransactionView')

export default [
  {
    path: '/client-transactions',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'transactions.clientTransactions',
        meta: { auth: true },
        component: ClientTransactions
      },
      {
        path: 'view/:id?',
        name: 'transactions.clientTransactionView',
        meta: { auth: true, currentPage: itemPages.view },
        component: ClientTransactionView,
        props: true
      }
    ]
  }
]
