import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Countries = () => import('./views/Countries')
const CountryView = () => import('./views/CountryView')

export default [
  {
    path: '/countries',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'generalSettings.countries',
        meta: { auth: true },
        component: Countries
      },
      {
        path: 'view/:id?',
        name: 'generalSettings.countryView',
        meta: { auth: true, currentPage: itemPages.view },
        component: CountryView,
        props: true
      },
      {
        path: 'create',
        name: 'generalSettings.countryCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: CountryView
      },
      {
        path: 'update/:id?',
        name: 'generalSettings.countryUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: CountryView,
        props: true
      }
    ]
  }
]
