import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Competitions = () => import('./views/Competitions')
const CompetitionView = () => import('./views/CompetitionView')

export default [
  {
    path: '/competitions',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.competitions',
        meta: { auth: true },
        component: Competitions
      },
      {
        path: 'view/:id?',
        name: 'events.competitionView',
        meta: { auth: true, currentPage: itemPages.view },
        component: CompetitionView,
        props: true
      },
      {
        path: 'create',
        name: 'events.competitionCreate',
        meta: { auth: true, currentPage: itemPages.create },
        component: CompetitionView
      },
      {
        path: 'update/:id?',
        name: 'events.competitionUpdate',
        meta: { auth: true, currentPage: itemPages.update },
        component: CompetitionView,
        props: true
      }
    ]
  }
]
