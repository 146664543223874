import { itemPages } from '../../../config/constantLists'
const DashboardLayout = () => import('../../../layouts/DashboardLayout.vue')
const Predictions = () => import('./views/Predictions')
const PredictionView = () => import('./views/PredictionView')

export default [
  {
    path: '/predictions',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'events.predictions',
        meta: { auth: true },
        component: Predictions
      },
      {
        path: 'view/:id?',
        name: 'events.predictionView',
        meta: { auth: true, currentPage: itemPages.view },
        component: PredictionView,
        props: true
      }
    ]
  }
]
